const phoneCodes =[
  {
    "name": "Türkiye",
    "code": "+90",
    "short_code": "TR"
  },
  {
    "name": "ABD Virgin Adaları",
    "code": "+1 340",
    "short_code": "VI"
  },
  {
    "code": "+93",
    "name": "Afganistan"
  },
  {
    "name": "Almanya",
    "code": "+49",
    "short_code": "DE"
  },
  {
    "name": "Amerika Birleşik Devletleri",
    "code": "+1",
    "short_code": "US"
  },
  {
    "name": "Andora",
    "code": "+376",
    "short_code": "AD"
  },
  {
    "name": "Angola",
    "code": "+244",
    "short_code": "AO"
  },
  {
    "name": "Anguilla",
    "code": "+1 264",
    "short_code": "AI"
  },
  {
    "name": "Antarktika",
    "code": "+672",
    "short_code": "AQ"
  },
  {
    "name": "Antigua ve Barbuda",
    "code": "+1268",
    "short_code": "AG"
  },
  {
    "name": "Arjantin",
    "code": "+54",
    "short_code": "AR"
  },
  {
    "name": "Arnavutluk",
    "code": "+355",
    "short_code": "AL"
  },
  {
    "name": "Aruba",
    "code": "+297",
    "short_code": "AW"
  },
  {
    "name": "Avustralya",
    "code": "+61",
    "short_code": "AU"
  },
  {
    "name": "Avusturya",
    "code": "+43",
    "short_code": "AT"
  },
  {
    "name": "Azerbaycan",
    "code": "+994",
    "short_code": "AZ"
  },
  {
    "name": "Bahamalar",
    "code": "+1 242",
    "short_code": "BS"
  },
  {
    "name": "Bahreyn",
    "code": "+973",
    "short_code": "BH"
  },
  {
    "name": "Bangladeş",
    "code": "+880",
    "short_code": "BD"
  },
  {
    "name": "Barbados",
    "code": "+1 246",
    "short_code": "BB"
  },
  {
    "name": "Belize",
    "code": "+501",
    "short_code": "BZ"
  },
  {
    "name": "Belçika",
    "code": "+32",
    "short_code": "BE"
  },
  {
    "name": "Benin",
    "code": "+229",
    "short_code": "BJ"
  },
  {
    "name": "Bermuda",
    "code": "+1 441",
    "short_code": "BM"
  },
  {
    "name": "Beyaz Rusya",
    "code": "+375",
    "short_code": "BY"
  },
  {
    "name": "Bhutan",
    "code": "+975",
    "short_code": "BT"
  },
  {
    "name": "Birleşik Arap Emirlikleri",
    "code": "+971",
    "short_code": "AE"
  },
  {
    "name": "Birleşik Krallık",
    "code": "+44",
    "short_code": "GB"
  },
  {
    "name": "Bolivya",
    "code": "+591",
    "short_code": "BO"
  },
  {
    "name": "Bosna Hersek",
    "code": "+387",
    "short_code": "BA"
  },
  {
    "name": "Botsvana",
    "code": "+267",
    "short_code": "BW"
  },
  {
    "name": "Brezilya",
    "code": "+55",
    "short_code": "BR"
  },
  {
    "name": "Brunei",
    "code": "+673",
    "short_code": "BN"
  },
  {
    "name": "Bulgaristan",
    "code": "+359",
    "short_code": "BG"
  },
  {
    "name": "Burkina Faso",
    "code": "+226",
    "short_code": "BF"
  },
  {
    "name": "Burundi",
    "code": "+257",
    "short_code": "BI"
  },
  {
    "name": "Cape Verde",
    "code": "+238",
    "short_code": "CV"
  },
  {
    "name": "Cebelitarık",
    "code": "+350",
    "short_code": "GI"
  },
  {
    "name": "Cezayir",
    "code": "+213",
    "short_code": "DZ"
  },
  {
    "name": "Christmas Adası",
    "code": "+61",
    "short_code": "CX"
  },
  {
    "name": "Cibuti",
    "code": "+253",
    "short_code": "DJ"
  },
  {
    "name": "Cocos Adaları",
    "code": "+61",
    "short_code": "CC"
  },
  {
    "name": "Cook Adaları",
    "code": "+682",
    "short_code": "CK"
  },
  {
    "name": "Çad",
    "code": "+235",
    "short_code": "TD"
  },
  {
    "name": "Çek Cumhuriyeti",
    "code": "+420",
    "short_code": "CZ"
  },
  {
    "name": "Çin",
    "code": "+86",
    "short_code": "CN"
  },
  {
    "name": "Danimarka",
    "code": "+45",
    "short_code": "DK"
  },
  {
    "name": "Dominik",
    "code": "+1 767",
    "short_code": "DM"
  },
  {
    "name": "Dominik Cumhuriyeti",
    "code": "+1 849",
    "short_code": "DO"
  },
  {
    "name": "Doğu Timor",
    "code": "+670",
    "short_code": "TL"
  },
  {
    "name": "Ekvator",
    "code": "+593",
    "short_code": "EC"
  },
  {
    "name": "Ekvator Ginesi",
    "code": "+240",
    "short_code": "GQ"
  },
  {
    "name": "El Salvador",
    "code": "+503",
    "short_code": "SV"
  },
  {
    "name": "Endonezya",
    "code": "+62",
    "short_code": "ID"
  },
  {
    "name": "Eritre",
    "code": "+291",
    "short_code": "ER"
  },
  {
    "name": "Ermenistan",
    "code": "+374",
    "short_code": "AM"
  },
  {
    "name": "Estonya",
    "code": "+372",
    "short_code": "EE"
  },
  {
    "name": "Etiyopya",
    "code": "+251",
    "short_code": "ET"
  },
  {
    "name": "Falkland Adaları (Malvinalar)",
    "code": "+500",
    "short_code": "FK"
  },
  {
    "name": "Faroe Adaları",
    "code": "+298",
    "short_code": "FO"
  },
  {
    "name": "Fas",
    "code": "+212",
    "short_code": "MA"
  },
  {
    "name": "Fiji",
    "code": "+679",
    "short_code": "FJ"
  },
  {
    "name": "Fildişi Sahilleri",
    "code": "+225",
    "short_code": "CI"
  },
  {
    "name": "Filipinler",
    "code": "+63",
    "short_code": "PH"
  },
  {
    "name": "Filistin Bölgesi",
    "code": "+970",
    "short_code": "PS"
  },
  {
    "name": "Finlandiya",
    "code": "+358",
    "short_code": "FI"
  },
  {
    "name": "Fransa",
    "code": "+33",
    "short_code": "FR"
  },
  {
    "name": "Fransız Guyanası",
    "code": "+594",
    "short_code": "GF"
  },
  {
    "name": "Fransız Polinezyası",
    "code": "+689",
    "short_code": "PF"
  },
  {
    "name": "Gabon",
    "code": "+241",
    "short_code": "GA"
  },
  {
    "name": "Gambia",
    "code": "+220",
    "short_code": "GM"
  },
  {
    "name": "Gana",
    "code": "+233",
    "short_code": "GH"
  },
  {
    "name": "Gine",
    "code": "+224",
    "short_code": "GN"
  },
  {
    "name": "Gine-Bissau",
    "code": "+245",
    "short_code": "GW"
  },
  {
    "name": "Granada",
    "code": "+1 473",
    "short_code": "GD"
  },
  {
    "name": "Grönland",
    "code": "+299",
    "short_code": "GL"
  },
  {
    "name": "Guadeloupe",
    "code": "+590",
    "short_code": "GP"
  },
  {
    "name": "Guam",
    "code": "+1 671",
    "short_code": "GU"
  },
  {
    "name": "Guatemala",
    "code": "+502",
    "short_code": "GT"
  },
  {
    "name": "Guernsey",
    "code": "+44",
    "short_code": "GG"
  },
  {
    "name": "Guyana",
    "code": "+595",
    "short_code": "GY"
  },
  {
    "name": "Güney Afrika",
    "code": "+27",
    "short_code": "ZA"
  },
  {
    "name": "Güney Georgia ve Güney Sandwich Adaları",
    "code": "+500",
    "short_code": "GS"
  },
  {
    "name": "Güney Kore",
    "code": "+82",
    "short_code": "KR"
  },
  {
    "name": "Güney Kıbrıs Rum Kesimi",
    "code": "+357",
    "short_code": "CY"
  },
  {
    "name": "Gürcistan",
    "code": "+995",
    "short_code": "GE"
  },
  {
    "name": "Haiti",
    "code": "+509",
    "short_code": "HT"
  },
  {
    "name": "Hindistan",
    "code": "+91",
    "short_code": "IN"
  },
  {
    "name": "Hint Okyanusu İngiliz Bölgesi",
    "code": "+246",
    "short_code": "IO"
  },
  {
    "name": "Hollanda",
    "code": "+31",
    "short_code": "NL"
  },
  {
    "name": "Hollanda Antilleri",
    "code": "+599",
    "short_code": "AN"
  },
  {
    "name": "Honduras",
    "code": "+504",
    "short_code": "HN"
  },
  {
    "name": "Hong Kong SAR - Çin",
    "code": "+852",
    "short_code": "HK"
  },
  {
    "name": "Hırvatistan",
    "code": "+385",
    "short_code": "HR"
  },
  {
    "name": "Irak",
    "code": "+964",
    "short_code": "IQ"
  },
  {
    "name": "İngiliz Virgin Adaları",
    "code": "+1 284",
    "short_code": "VG"
  },
  {
    "name": "İran",
    "code": "+98",
    "short_code": "IR"
  },
  {
    "name": "İrlanda",
    "code": "+353",
    "short_code": "IE"
  },
  {
    "name": "İspanya",
    "code": "+34",
    "short_code": "ES"
  },
  {
    "name": "İsrail",
    "code": "+972",
    "short_code": "IL"
  },
  {
    "name": "İsveç",
    "code": "+46",
    "short_code": "SE"
  },
  {
    "name": "İsviçre",
    "code": "+41",
    "short_code": "CH"
  },
  {
    "name": "İtalya",
    "code": "+39",
    "short_code": "IT"
  },
  {
    "name": "İzlanda",
    "code": "+354",
    "short_code": "IS"
  },
  {
    "name": "Jamaika",
    "code": "+1 876",
    "short_code": "JM"
  },
  {
    "name": "Japonya",
    "code": "+81",
    "short_code": "JP"
  },
  {
    "name": "Jersey",
    "code": "+44",
    "short_code": "JE"
  },
  {
    "name": "Kamboçya",
    "code": "+855",
    "short_code": "KH"
  },
  {
    "name": "Kamerun",
    "code": "+237",
    "short_code": "CM"
  },
  {
    "name": "Kanada",
    "code": "+1",
    "short_code": "CA"
  },
  {
    "name": "Karadağ",
    "code": "+382",
    "short_code": "ME"
  },
  {
    "name": "Katar",
    "code": "+974",
    "short_code": "QA"
  },
  {
    "name": "Kayman Adaları",
    "code": "+ 345",
    "short_code": "KY"
  },
  {
    "name": "Kazakistan",
    "code": "+7 7",
    "short_code": "KZ"
  },
  {
    "name": "Kenya",
    "code": "+254",
    "short_code": "KE"
  },
  {
    "name": "Kiribati",
    "code": "+686",
    "short_code": "KI"
  },
  {
    "name": "Kolombiya",
    "code": "+57",
    "short_code": "CO"
  },
  {
    "name": "Komorlar",
    "code": "+269",
    "short_code": "KM"
  },
  {
    "name": "Kongo",
    "code": "+242",
    "short_code": "CG"
  },
  {
    "name": "Kongo Demokratik Cumhuriyeti",
    "code": "+243",
    "short_code": "CD"
  },
  {
    "name": "Kosova",
    "code": "+383",
    "short_code": "RKS"
  },
  {
    "name": "Kosta Rika",
    "code": "+506",
    "short_code": "CR"
  },
  {
    "name": "Kuveyt",
    "code": "+965",
    "short_code": "KW"
  },
  {
    "name": "Kuzey Kore",
    "code": "+850",
    "short_code": "KP"
  },
  {
    "name": "Kuzey Mariana Adaları",
    "code": "+1 670",
    "short_code": "MP"
  },
  {
    "name": "Küba",
    "code": "+53",
    "short_code": "CU"
  },
  {
    "name": "Kırgızistan",
    "code": "+996",
    "short_code": "KG"
  },
  {
    "name": "Laos",
    "code": "+856",
    "short_code": "LA"
  },
  {
    "name": "Lesotho",
    "code": "+266",
    "short_code": "LS"
  },
  {
    "name": "Letonya",
    "code": "+371",
    "short_code": "LV"
  },
  {
    "name": "Liberya",
    "code": "+231",
    "short_code": "LR"
  },
  {
    "name": "Libya",
    "code": "+218",
    "short_code": "LY"
  },
  {
    "name": "Liechtenstein",
    "code": "+423",
    "short_code": "LI"
  },
  {
    "name": "Litvanya",
    "code": "+370",
    "short_code": "LT"
  },
  {
    "name": "Lübnan",
    "code": "+961",
    "short_code": "LB"
  },
  {
    "name": "Lüksemburg",
    "code": "+352",
    "short_code": "LU"
  },
  {
    "name": "Macaristan",
    "code": "+36",
    "short_code": "HU"
  },
  {
    "name": "Madagaskar",
    "code": "+261",
    "short_code": "MG"
  },
  {
    "name": "Makao S.A.R. Çin",
    "code": "+853",
    "short_code": "MO"
  },
  {
    "name": "Makedonya",
    "code": "+389",
    "short_code": "MK"
  },
  {
    "name": "Malavi",
    "code": "+265",
    "short_code": "MW"
  },
  {
    "name": "Maldivler",
    "code": "+960",
    "short_code": "MV"
  },
  {
    "name": "Malezya",
    "code": "+60",
    "short_code": "MY"
  },
  {
    "name": "Mali",
    "code": "+223",
    "short_code": "ML"
  },
  {
    "name": "Malta",
    "code": "+356",
    "short_code": "MT"
  },
  {
    "name": "Man Adası",
    "code": "+44",
    "short_code": "IM"
  },
  {
    "name": "Marshall Adaları",
    "code": "+692",
    "short_code": "MH"
  },
  {
    "name": "Martinik",
    "code": "+596",
    "short_code": "MQ"
  },
  {
    "name": "Mauritius",
    "code": "+230",
    "short_code": "MU"
  },
  {
    "name": "Mayotte",
    "code": "+262",
    "short_code": "YT"
  },
  {
    "name": "Meksika",
    "code": "+52",
    "short_code": "MX"
  },
  {
    "name": "Mikronezya Federal Eyaletleri",
    "code": "+691",
    "short_code": "FM"
  },
  {
    "name": "Moldovya Cumhuriyeti",
    "code": "+373",
    "short_code": "MD"
  },
  {
    "name": "Monako",
    "code": "+377",
    "short_code": "MC"
  },
  {
    "name": "Montserrat",
    "code": "+1664",
    "short_code": "MS"
  },
  {
    "name": "Moritanya",
    "code": "+222",
    "short_code": "MR"
  },
  {
    "name": "Mozambik",
    "code": "+258",
    "short_code": "MZ"
  },
  {
    "name": "Moğolistan",
    "code": "+976",
    "short_code": "MN"
  },
  {
    "name": "Myanmar",
    "code": "+95",
    "short_code": "MM"
  },
  {
    "name": "Mısır",
    "code": "+20",
    "short_code": "EG"
  },
  {
    "name": "Namibya",
    "code": "+264",
    "short_code": "NA"
  },
  {
    "name": "Nauru",
    "code": "+674",
    "short_code": "NR"
  },
  {
    "name": "Nepal",
    "code": "+977",
    "short_code": "NP"
  },
  {
    "name": "Nijer",
    "code": "+227",
    "short_code": "NE"
  },
  {
    "name": "Nijerya",
    "code": "+234",
    "short_code": "NG"
  },
  {
    "name": "Nikaragua",
    "code": "+505",
    "short_code": "NI"
  },
  {
    "name": "Niue",
    "code": "+683",
    "short_code": "NU"
  },
  {
    "name": "Norfolk Adası",
    "code": "+672",
    "short_code": "NF"
  },
  {
    "name": "Norveç",
    "code": "+47",
    "short_code": "NO"
  },
  {
    "name": "Orta Afrika Cumhuriyeti",
    "code": "+236",
    "short_code": "CF"
  },
  {
    "name": "Özbekistan",
    "code": "+998",
    "short_code": "UZ"
  },
  {
    "name": "Pakistan",
    "code": "+92",
    "short_code": "PK"
  },
  {
    "name": "Palau",
    "code": "+680",
    "short_code": "PW"
  },
  {
    "name": "Panama",
    "code": "+507",
    "short_code": "PA"
  },
  {
    "name": "Papua Yeni Gine",
    "code": "+675",
    "short_code": "PG"
  },
  {
    "name": "Paraguay",
    "code": "+595",
    "short_code": "PY"
  },
  {
    "name": "Peru",
    "code": "+51",
    "short_code": "PE"
  },
  {
    "name": "Pitcairn",
    "code": "+872",
    "short_code": "PN"
  },
  {
    "name": "Polonya",
    "code": "+48",
    "short_code": "PL"
  },
  {
    "name": "Portekiz",
    "code": "+351",
    "short_code": "PT"
  },
  {
    "name": "Porto Riko",
    "code": "+1 939",
    "short_code": "PR"
  },
  {
    "name": "Reunion",
    "code": "+262",
    "short_code": "RE"
  },
  {
    "name": "Romanya",
    "code": "+40",
    "short_code": "RO"
  },
  {
    "name": "Ruanda",
    "code": "+250",
    "short_code": "RW"
  },
  {
    "name": "Rusya Federasyonu",
    "code": "+7",
    "short_code": "RU"
  },
  {
    "name": "Samoa",
    "code": "+685",
    "short_code": "WS"
  },
  {
    "name": "San Marino",
    "code": "+378",
    "short_code": "SM"
  },
  {
    "name": "Sao Tome ve Principe",
    "code": "+239",
    "short_code": "ST"
  },
  {
    "name": "Senegal",
    "code": "+221",
    "short_code": "SN"
  },
  {
    "name": "Seyşeller",
    "code": "+248",
    "short_code": "SC"
  },
  {
    "name": "Sierra Leone",
    "code": "+232",
    "short_code": "SL"
  },
  {
    "name": "Singapur",
    "code": "+65",
    "short_code": "SG"
  },
  {
    "name": "Slovakya",
    "code": "+421",
    "short_code": "SK"
  },
  {
    "name": "Slovenya",
    "code": "+386",
    "short_code": "SI"
  },
  {
    "name": "Solomon Adaları",
    "code": "+677",
    "short_code": "SB"
  },
  {
    "name": "Somali",
    "code": "+252",
    "short_code": "SO"
  },
  {
    "name": "Sri Lanka",
    "code": "+94",
    "short_code": "LK"
  },
  {
    "name": "Sudan",
    "code": "+249",
    "short_code": "SD"
  },
  {
    "name": "Surinam",
    "code": "+597",
    "short_code": "SR"
  },
  {
    "name": "Suriye",
    "code": "+963",
    "short_code": "SY"
  },
  {
    "name": "Suudi Arabistan",
    "code": "+966",
    "short_code": "SA"
  },
  {
    "name": "Svalbard ve Jan Mayen",
    "code": "+47",
    "short_code": "SJ"
  },
  {
    "name": "Svaziland",
    "code": "+268",
    "short_code": "SZ"
  },
  {
    "name": "Sırbistan",
    "code": "+381",
    "short_code": "RS"
  },
  {
    "name": "Şili",
    "code": "+56",
    "short_code": "CL"
  },
  {
    "name": "Tacikistan",
    "code": "+992",
    "short_code": "TJ"
  },
  {
    "name": "Tanzanya",
    "code": "+255",
    "short_code": "TZ"
  },
  {
    "name": "Tayland",
    "code": "+66",
    "short_code": "TH"
  },
  {
    "name": "Tayvan",
    "code": "+886",
    "short_code": "TW"
  },
  {
    "name": "Togo",
    "code": "+228",
    "short_code": "TG"
  },
  {
    "name": "Tokelau",
    "code": "+690",
    "short_code": "TK"
  },
  {
    "name": "Tonga",
    "code": "+676",
    "short_code": "TO"
  },
  {
    "name": "Trinidad ve Tobago",
    "code": "+1 868",
    "short_code": "TT"
  },
  {
    "name": "Tunus",
    "code": "+216",
    "short_code": "TN"
  },
  {
    "name": "Turks ve Caicos Adaları",
    "code": "+1 649",
    "short_code": "TC"
  },
  {
    "name": "Tuvalu",
    "code": "+688",
    "short_code": "TV"
  },
  {
    "name": "Türkmenistan",
    "code": "+993",
    "short_code": "TM"
  },
  {
    "name": "Uganda",
    "code": "+256",
    "short_code": "UG"
  },
  {
    "name": "Ukrayna",
    "code": "+380",
    "short_code": "UA"
  },
  {
    "name": "Umman",
    "code": "+968",
    "short_code": "OM"
  },
  {
    "name": "Uruguay",
    "code": "+598",
    "short_code": "UY"
  },
  {
    "name": "Ürdün",
    "code": "+962",
    "short_code": "JO"
  },
  {
    "name": "Vanuatu",
    "code": "+678",
    "short_code": "VU"
  },
  {
    "name": "Vatikan",
    "code": "+379",
    "short_code": "VA"
  },
  {
    "name": "Venezuela",
    "code": "+58",
    "short_code": "VE"
  },
  {
    "name": "Vietnam",
    "code": "+84",
    "short_code": "VN"
  },
  {
    "name": "Wallis ve Futuna",
    "code": "+681",
    "short_code": "WF"
  },
  {
    "name": "Yemen",
    "code": "+967",
    "short_code": "YE"
  },
  {
    "name": "Yeni Kaledonya",
    "code": "+687",
    "short_code": "NC"
  },
  {
    "name": "Yeni Zelanda",
    "code": "+64",
    "short_code": "NZ"
  },
  {
    "name": "Yunanistan",
    "code": "+30",
    "short_code": "GR"
  },
  {
    "name": "Zambiya",
    "code": "+260",
    "short_code": "ZM"
  },
  {
    "name": "Zimbabve",
    "code": "+263",
    "short_code": "ZW"
  }
]

const phoneOptionsTurkish = [];
phoneCodes.forEach(obj => {
  phoneOptionsTurkish.push({ label: `${obj.code}(${obj.name})`, value: obj.code });
});
export { phoneOptionsTurkish, phoneCodes } ;